import React from 'react';
import { graphql, useStaticQuery} from "gatsby";
import Img from "gatsby-image"
import {Link, useI18next, I18nextContext} from 'gatsby-plugin-react-i18next';

import { Link as ScrollToLink } from 'react-scroll'
import styled from "styled-components"
import SectionHeader from './section-header';
import Divider from '@material-ui/core/Divider';
import Button from '@material-ui/core/Button';

import DownloadPdfHandler from "./downloadpdfhandler"

import RBC from '../images/rbc.jpg'
import backgroundImage from '../images/background-books.jpg'


const Container = styled.div`
    width: 100%;
    min-height: calc(100vh - 40px);
    text-align: center;
    margin: 0 auto;
    margin-top: -131px;
    padding-top: 131px;
    background-image: url(${(props)=>props.backgroundImage});
    background-attachment: fixed;
    background-size: cover;
    background-repeat: no-repeat;

    @media screen and (max-width: 1220px) {
        min-height: calc(100vh - 40px);
        margin-top: 0px;
        padding-top: 0;
    }

    @media screen and (max-width: 1100px) {
        min-height: calc(100vh - 40px);
        margin-top: 0;
        padding-top: 0;
        padding-top: 75px;
    }

`

const Section = styled.div`
    
`


const ContentWrapper = styled.div`
    margin: 0 auto;
    text-align: center;
    background-color: ${props=>props.$backgroundColour};
    color: ${props=>props.$colour};
    padding-top: 60px;
    padding-bottom: 60px;
`

const ContentInner = styled.div`
    width: 73.306%;
    margin: 0 auto;
    // float: left;
    
    margin-left: 0;
    margin-right: 0;
   
    @media screen and (max-width: 810px) {
        width: 100%;
    }

`

const ArticlesContainer = styled.div`
    display: flex;
    flex-direction: column;
`

const Article = styled.div`
    display: flex;
    margin-top: 16px;
    margin-bottom: 16px;
    background: white;
    padding: 32px;
    @media screen and (max-width: 810px) {
        flex-direction: column;
    }
`

const ArticleInner = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    
    h1 {
        text-align: left;
        
        // &:hover {
        //     color: #ff8000;
        // }
    }
    
    h2 {
        text-align: left;
        font-weight: 100;
    }
    
    ul {
        text-align: left;
        font-size: 18px;
        list-style-position: inside;
    }
`


const BookCoverWrapper = styled.div`
    width: 30%;
    overflow: hidden;
    
    
    &.to-the-left {
        float: left;
        margin-right: 16px;
    }
    
    &.to-the-right {
        float: right;
        margin-left: 16px;
    }
    
    img {
        width: 100%;
        height: auto;
        object-fit: cover;
        object-position: center;
    }
`

const BookTitleWrapper = styled.div`
    margin-bottom: 16px;
`
const ArticleButton = styled(Button)`
    width: 160px;
`

const SideBar = styled.div`
    float: right;
    width: 25%;
    
`

const ArticleCollection = styled.div`
    background: white;
    padding: 16px;
    margin-top: 16px;
    
    
    h3 {
        text-align: left;
        margin-bottom: 16px;
        font-weight: 400;
        font-size: 18px;
        
        &:hover {
            color: #ff8000;
            
        }
    }
`

const Content = styled.div`
    margin: 0 auto;
    margin-top: 40px;
    width: 90%;
    max-width: 90%;
    max-width: 1372px;
    display: flex;
    justify-content: space-between; // For sidebar
    justify-content: center;

    
    p { 
        
        font-size: 18px;
        line-height: 1.4;
        // margin-top: 14px;
        font-weight: 400;
        white-space: pre-wrap;
        margin-bottom: 14px;
        text-align: left;
    }
    
  
`

const BookCollection = styled.div`
    position: sticky;
    top: 106px;
    display: flex;
    flex-wrap: wrap;
    margin-top: 16px;
    
    a {
        width: 24%;
        width: 85px;
        // height: 120px;
        margin-left: 5px;
        margin-bottom: 5px;
        cursor: pointer;
        
        .gatsby-image-wrapper {
            height: 125px;
        }
        
        img {
            width: auto;
            height: 100%;
            object-fit: cover;
        }
    }

`

const ReadMoreUrl = styled.div`
    width: auto;
    text-align: left;
    font-size: 18px;
`

const ArticleImageWrapper = styled.div`
    width: 100%;
    overflow: hidden;
    
    img {
        width: 100%;
        height: 400px;
        object-fit: cover;
        object-position: center;
    }
`
const PublishDate = styled.div`
    width: 60px;
    height: 100px;
    margin-right: 16px;
    background-color: #2c466b;
    color: white;
    padding-top: 8px;
    span {
        text-align: center;
        display: block;

        @media screen and (max-width: 810px) {
            margin-right: 5px;
        }
    }

    @media screen and (max-width: 810px) {
        width: fit-content;
        height: auto;
        display: flex;
        padding: 0 0 0 5px;
    }
  
    
`

const LinkButton = styled(Link)`
    color: #fff;
    background-color: #2c466b;
    width: 160px;
    padding: 6px 16px;
    font-size: 0.875rem;
    min-width: 64px;
    box-sizing: border-box;
    font-family: century-gothic,sans-serif;
    font-weight: 500;
    line-height: 1.75;
    border-radius: 4px;
    text-transform: uppercase;
    cursor: pointer;
    
    &:hover {
        color: white;
        color: #ff8000;
        // text-stroke: 0;
        // -webkit-text-stroke: 0;
    }
`
/* 
Date.prototype.getMonthNameShort = function(lang) {
    lang = lang && (lang in Date.locale) ? lang : 'en';
    return Date.locale[lang].month_names_short[this.getMonth()];
};

Date.prototype.getMonthName = function(lang) {
    lang = lang && (lang in Date.locale) ? lang : 'en';
    return Date.locale[lang].month_names[this.getMonth()];
};

Date.locale = {
    en: {
       month_names: ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'],
       month_names_short: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']
    },

    hu: {
       month_names: ['Január', 'Február', 'Március', 'Április', 'Május', 'Június', 'Július', 'Augusztus', 'Szeptember', 'Október', 'November', 'December'],
       month_names_short: ['Jan', 'Feb', 'Már', 'Ápr', 'Máj', 'Jún', 'Júl', 'Aug', 'Szep', 'Okt', 'Nov', 'Dec']
    }
}; */



const ArticlesContent = () => {
    
    const context = React.useContext(I18nextContext);
    
    const {t} = useI18next();
    const language = context.language;
    
    const items = useStaticQuery(graphql`
      query {
        allMarkdownRemark(filter: {fileAbsolutePath: {regex: "/(articles)/"  }}, sort: {fields: frontmatter___createdAt, order: ASC}) {
          edges {
            node {
              frontmatter {
                title
                excerpt
                file
                image
                createdAt
                updatedAt
                language
              }
              html
              id
            }
          }
        }
      }
    `)

    let edges = items.allMarkdownRemark.edges
    let articlesFilteredByLanguage = edges.filter(edge=>edge.node.frontmatter.language == language)

    return (
        <Container backgroundImage={backgroundImage}>       
            <ContentWrapper id="mission" >
                <SectionHeader id="section-header" title={t("PUBLICATIONS.ARTICLES-WHITE-PAPERS")}/>
                <Content>
                    <ContentInner>
                        <ArticlesContainer>
                            {
                                articlesFilteredByLanguage.map((article, index)=>{
                                    
                                    let isoDate
                                    let year
                                    let month

                                    if (article.node.frontmatter.createdAt !== null) {
                                        isoDate = new Date(article.node.frontmatter.createdAt)
                                        year = isoDate.getFullYear()
                                        month = isoDate.getMonth()
                                        month = isoDate.toLocaleString(language === "en" ? 'en-GB' : 'hu-HU', { month: 'short' });
                                    }
            
                                    
                                    return (
                                        <Article key={article.node.id}>
                                            <PublishDate>
                                                <span>{year}</span>
                                                   <Divider light={true}/>
                                                <span>{month}</span>
                                            </PublishDate>
                                            <ArticleInner>
                                                {
                                                    article.node.frontmatter.image && 
                                                    <ArticleImageWrapper>
                                                        <img src={RBC} alt="" />
                                                    </ArticleImageWrapper>
                                                }
                                                
                                            {/*<Link to="/publications/articles/new-article-with-image">*/}
                                                <h1>{article.node.frontmatter.title}</h1>
                                            {/*</Link>*/}
                                                <p>{article.node.frontmatter.excerpt}</p>
                                                {
                                                    article.node.html &&
                                                    <LinkButton to="/publications/articles/new-article-with-image">Read more</LinkButton>
                                                }
                                                {
                                                    article.node.frontmatter.file && !article.node.html &&
                                                    <React.Fragment>
                                                    <DownloadPdfHandler pdf={article.node.frontmatter.file} presentationTitle={article.node.frontmatter.title} />
                                                    </React.Fragment>
                                                }
                                                
                                            </ArticleInner>
                                            
                                        </Article>
                                    )
                                    
                                })
                            }
                        </ArticlesContainer>
                        
                    </ContentInner>
                    {/*<SideBar>
                        <ArticleCollection>
                            {
                                articlesFilteredByLanguage.map((article)=>{
                                    return (
                                            <Link key={article.node.id} to=""><h3>{article.node.frontmatter.title}</h3></Link>
                                    )
                                })
                            }
                        </ArticleCollection>
                        
                    </SideBar>*/}
                </Content>
            </ContentWrapper>
        </Container>
    
    )
}

export default ArticlesContent